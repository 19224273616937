import React, { useState, useRef } from "react";
import emailjs from "emailjs-com";
import "./form.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import Success from "./User/Success";
import toast from "react-hot-toast";

function Form() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function validate() {
    if (name === "") {
      toast.error("Name is Empty");
      return false;
    } else if (!/^[A-Za-z -]{3,25}$/.test(name)) {
      toast.error("Invalid Name");
      return false;
    }
    if (phone === "") {
      toast.error("Phone number is Empty");
      return false;
    } else if (!/^\d{10}$/.test(phone)) {
      toast.error("Invalid phone number");
      return false;
    }

    if (email === "") {
      toast.error("Email is Empty");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      toast.error("Invalid email address");
      return false;
    }
    return true;
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ol721aw",
        "template_cr5q2dh",
        form.current,
        "odYM0GOidkMK3vBfk"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      sendEmail(e);
      toast.success("Message Sent Successfully");
    }
  };

  return (
    <div className="bg-zinc-950 form-padding" id="form">
      <Helmet>
        <title>Tri2champ</title>
        <link rel="icon" type="image/png" href="logo1.png" />
      </Helmet>
      <div className="form-container rounded-xl  bg-transparent">
        <p
          style={{ fontSize: "2rem", color: "white", textAlign: "center" }}
          className="tiltneon pb-3"
        >
          Connect to us
        </p>
        <form onSubmit={handleSubmit} ref={form} className="bgcolor ">
          <input
            name="from_name"
            type="text"
            id="from_name"
            value={name}
            className="rounded iinput mb-3 rounded-xl text-zinc-950 border-b-4 border-cyan-400 hover:border-2 pt-2 border-cyan-600 bg-gradient-to-b from-zinc-50 to-teal-200 rounded-xl"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            name="email"
            id="email"
            className="rounded iinput mb-3 rounded-xl text-zinc-950 border-b-4 border-cyan-400 hover:border-2 pt-2 border-cyan-600 bg-gradient-to-b from-zinc-50 to-teal-200 rounded-xl"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="phone"
            name="phone"
            id="phone"
            className="rounded iinput mb-3 rounded-xl text-zinc-950 border-b-4 border-cyan-400 hover:border-2 pt-2 border-cyan-600 bg-gradient-to-b from-zinc-50 to-teal-200 rounded-xl"
            value={phone}
            placeholder="Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea
            id="message"
            name="message"
            value={message}
            style={{ color: "aliceblue" }}
            className="rounded iinput mb-3 rounded-xl text-zinc-950 border-b-4 border-cyan-400 hover:border-2 pt-2 border-cyan-600 bg-gradient-to-b from-zinc-50 to-teal-200 rounded-xl"
            placeholder="Feel free to ask anything"
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <br />
          <button
            type="submit"
            className="rounded-pill bg-gradient-to-b hover:bg-gradient-to-b from-emerald-600 to-blue-300 hover:from-blue-300 hover:to-emerald-600 tiltneon bbutton"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
