import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import "./section3.css";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import { app as firebaseApp } from "./firebase";

const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);
const imagesRef = storageRef(storage, "event");

const EventItem = ({ item, status, date, handleView }) => (
  <tr className="table-row" onClick={() => handleView(item)}>
    <td className="font-bold">{item}</td>
    <td className="font-bold">{status}</td>
    <td className="font-bold">{date}</td>
  </tr>
);

const Section3 = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [images, setImages] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    const menuRef = ref(database, "Event/List/");
    onValue(menuRef, (snapshot) => {
      const dat = snapshot.val();
      if (dat) {
        const menuData = Object.values(dat);
        setMenuItems(menuData);
      }
    });
  }, []);

  useEffect(() => {
    const menuRef = ref(database, "Event/");
    onValue(menuRef, (snapshot) => {
      const dat = snapshot.val();
      if (dat) {
        setData(dat);
      }
    });
  }, [menuItems]);

  //   useEffect(() => {
  //     const fetchImages = async () => {
  //       const imagePromises = [];
  //       for (const item of menuItems) {
  //         const itemImagesRef = storageRef(storage, Event/${item}/img1.jpg);
  //         const imageUrl = await getDownloadURL(itemImagesRef);
  //         imagePromises.push(imageUrl);
  //       }
  //       Promise.all(imagePromises)
  //         .then((urls) => {
  //           setImages(urls);
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     };

  //     if (menuItems.length > 0) {
  //       fetchImages();
  //     }
  //   }, [menuItems]);

  const handleView = (eventName) => {
    localStorage.setItem("Event", eventName);
    window.location.replace("./Login");
  };

  const renderMenuItems = () => {
    if (data !== undefined) {
      return menuItems.map((item, index) => {
        var status = "----";
        var date = "----";
        console.log(data[item]);
        if (
          data[item] &&
          (data[item].status !== undefined || data[item].status !== "")
        ) {
          status = data[item].status;
        }
        if (
          data[item] &&
          (data[item].date !== undefined || data[item].date !== "")
        ) {
          date = data[item].date;
        }
        return (
          <EventItem
            key={index}
            item={item}
            status={status}
            date={date}
            handleView={handleView}
          />
        );
      });
    }
  };

  return (
    <div className="min-h-1/2 bg-zinc-950 pt-10 pb-10">
      <div className="w-1/2 mx-auto justify-center items-center flex flex-col">
        <table className="event-table w-1/2">
          <thead className="border-2 border-zinc-300">
            <tr className="border-2 border-zinc-300">
              <th>Event</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody className="border-2 border-zinc-300">
            {renderMenuItems()}
          </tbody>
        </table>
        <a href="./Login">
          <button className="btn border-t-neutral-50 btn-primary w-full mt-5 rounded border-2 border-zinc-50">
            View all Events
          </button>
        </a>
      </div>
    </div>
  );
};

export default Section3;
