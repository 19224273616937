import React from 'react';

const Header = () => {
    return (
        <nav className="bg-teal-600 font-semibold text-xl flex mx-auto justify-center">
            <p className='py-2 text-white'>Explore our merchandise site <a href='/' className='underline text-blue-400 hover:text-blue-300'>here</a></p>
        </nav>
    )
}

export default Header;