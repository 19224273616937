import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import "./regev.css";
import Navbar from "./nav";
import Eventreg from "./eventreg";
import { Link } from "react-router-dom";
import Footer from "./footer";
import { firebaseConfig } from "../firebase";
import imageevent from "./event.jpg";
import pdf1 from "../pdf1.pdf";
import pdf from "../pdf.pdf";
import pdf2 from "../pdf2.pdf";
import pdf3 from "../pdf3.pdf";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

function newLine(text) {
  if (!text) return null;

  const sent = text;

  return sent.split("\n").map((line, index) => {
    if (line.includes("<b>") && line.includes("</b>")) {
      const parts = line.split(/(<b>.*<\/b>)/);
      return parts.map((part, i) => {
        if (part.startsWith("<b>") && part.endsWith("</b>")) {
          const boldText = part.replace(/<\/?b>/g, "");
          return <b key={index + i}>{boldText}</b>;
        } else {
          return (
            <React.Fragment key={index + i}>
              {part}
              <br />
            </React.Fragment>
          );
        }
      });
    } else {
      return (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      );
    }
  });
}

const EventDescription = () => {
  const [images, setImages] = useState([]);
  const [Gallery, setGallery] = useState([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const event = localStorage.getItem("Event");
  const imagesRef = storageRef(storage, "Event/" + event + "/");
  const GalleryRef = storageRef(
    storage,
    "Event/" + localStorage.getItem("Event") + "/gallery/"
  );

  var menuItems = JSON.parse(localStorage.getItem(event));
  useEffect(() => {
    const menuRef = ref(database, "Event/" + event);
    onValue(menuRef, (snapshot) => {
      const data = snapshot.val();
      localStorage.setItem(event, JSON.stringify(data));
    });
  }, []);

  useEffect(() => {
    listAll(GalleryRef)
      .then((ress) => {
        const imagePromises2 = ress.items.map((item) => getDownloadURL(item));
        Promise.all(imagePromises2)
          .then((urlss) => {
            setGallery(urlss);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUID") === "") {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    listAll(imagesRef)
      .then((res) => {
        const imagePromises = res.items.map((item) => getDownloadURL(item));
        Promise.all(imagePromises)
          .then((urls) => {
            setImages(urls);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleRegister = () => {
    window.location.replace("https://www.townscript.com/e/copy-of-Aquafest-Kanyakumari28-1-24");
  };
  const calculateAge = (minAge, maxAge) => {
    if (maxAge == 100 && minAge == 0) {
      return "Open";
    } else if (minAge == 0) {
      return "Below " + maxAge;
    } else if (maxAge == 100) {
      return "Above " + minAge;
    } else {
      return minAge + " - " + maxAge;
    }
  };

  const currentPath = window.location.pathname;
  const imageUrl = images[0];
  if (currentPath === "/register") {
    return <Eventreg />;
  } else {
    return (
      <div className="bg-gradient-to-r min-h-screen from-zinc-950 to-zinc-950">
        {menuItems && (
          <div>
            <Navbar />
            <div className="main-container pt-4 text-zinc-50">
              <Tabs>
                <TabList className="my-custom-tab-list text-blue-500">
                  <Tab className="my-custom-tab text-blue-500">Event Info</Tab>
                  <Tab className="my-custom-tab text-blue-500">
                    Event Gallery
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className="event-container bg-zinc-900 md:bg-zinc-900">
                    <div className="event-item bg-zinc-900 md:bg-zinc-900">
                      <div className="image-container flex items-center lg:hidden justify-center">
                        {imageUrl && (
                          <img src={imageUrl} className="event-image hovv" />
                        )}
                      </div>

                      <div className="event-details w-full flex-1">
                        <h2 className="event-title mx-auto text-center fs-1 mb-4 pb-4 font-bold w-full main-titless">
                          <br />
                          {newLine(menuItems.itemName)}
                        </h2>
                        <div className="event-fields">
                          {menuItems.field1 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles  font-bold  fs-3">
                                About Us & Event
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.description)}
                              </span>
                            </p>
                          )}
                          {menuItems.price && (
                            <div className="fs-6 mb-5">
                              <span className="main-titles font-bold fs-3">
                                Fees:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.price)}
                              </span>
                            </div>
                          )}

                          {menuItems.field2 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Mission:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field2)}
                              </span>
                            </p>
                          )}
                          {menuItems.field3 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Event Highlights and Participant Facilities:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field3)}
                              </span>
                            </p>
                          )}
                          {menuItems.field4 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Mandatory Accessories:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field4)}
                              </span>
                            </p>
                          )}
                          {menuItems.field5 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Event Information:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field5)}
                              </span>
                            </p>
                          )}
                          <p className="main-titles font-bold mb-1 fs-3">
                            Refund Policy
                          </p>
                          <div className="mb-5 leading-7">
                            No refunds will be issued under any circumstances.
                            Refer to our refund policy
                            <a
                              href={pdf1}
                              className=" pb-2 text-blue-500"
                              target="_blank"
                            >
                              &nbsp;<span className="underline">here</span>.
                            </a>
                          </div>
                          {menuItems.field6 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Race Kit Collection Requirements:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field6)}
                              </span>
                            </p>
                          )}
                          {menuItems.field7 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Consequences of Non-compliance:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field7)}
                              </span>
                            </p>
                          )}
                          {menuItems.field8 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Requesting Assistance:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field8)}
                              </span>
                            </p>
                          )}
                          {menuItems.field9 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Respect Toward Volunteers and Race Officials:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field9)}
                              </span>
                            </p>
                          )}
                          {menuItems.field10 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Identity Confirmation and Consequences:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field10)}
                              </span>
                            </p>
                          )}
                          {menuItems.field11 && (
                            <p className="fs-6 mb-5">
                              <span className="main-titles font-bold  fs-3">
                                Transportation Options:
                              </span>
                              <br />
                              <span className="leading-7">
                                {newLine(menuItems.field11)}
                              </span>
                            </p>
                          )}
                          <p className="main-titles font-bold mb-1 mt-3 fs-3">
                            Acknowledgment:
                          </p>
                          <ul className="mb-5 fs-6 leading-7">
                            <li>
                              {" "}
                              &bull;&nbsp;By registering for this event,
                              participants acknowledge and accept all the above
                              terms and conditions. If u want more information
                              <a href={pdf} className="text-blue-500 ">
                                &nbsp;
                                <span className="underline">Click here.</span>
                              </a>
                            </li>
                          </ul>
                          {/* {menuItems.data && (
                            <div className="rounded">
                              <h2 className="main-titles pb-4 fs-3 font-bold mb-3">
                                Categories
                              </h2>
                              <table
                                style={{
                                  color: "black",
                                  border: "2px solid purple",
                                  width: "100%",
                                  borderRadius: "30px",
                                }}
                                className="p-4 mx-auto"
                              >
                                <thead>
                                  <tr
                                    style={{
                                      backgroundColor: "orange",
                                      color: "white",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <th
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      Age
                                    </th>
                                    <th
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      Price
                                    </th>
                                    <th
                                      style={{
                                        border: "2px solid black",
                                        textAlign: "center",
                                        padding: "8px",
                                      }}
                                    >
                                      Description
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {menuItems.data.map(
                                    (item, index) =>
                                      item && (
                                        <tr
                                          key={index}
                                          style={{
                                            backgroundColor:
                                              index % 2 === 0
                                                ? "#f2f2f2"
                                                : "white",
                                          }}
                                        >
                                          <td
                                            style={{
                                              border: "2px solid black",
                                              textAlign: "center",
                                              padding: "8px",
                                            }}
                                          >
                                            {calculateAge(
                                              item.minAge,
                                              item.maxAge
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              border: "2px solid black",
                                              textAlign: "center",
                                              padding: "8px",
                                            }}
                                          >
                                            {item.price}
                                          </td>
                                          <td
                                            style={{
                                              border: "2px solid black",
                                              textAlign: "center",
                                              padding: "8px",
                                            }}
                                          >
                                            {menuItems.price}
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )} */}
                          <p className="mb-2 fs-6 mt-4 font-bold">
                            Click
                            <a href={pdf2} className="text-blue-500">
                              &nbsp;
                              <span className="underline font-bold">here</span>
                              &nbsp;
                            </a>
                            for Waiver Indemnity Form
                          </p>
                          <p className="mb-2 fs-6 font-bold">
                            Click
                            <a href={pdf3} className="text-blue-500">
                              &nbsp;
                              <span className="underline font-bold">here</span>
                              &nbsp;
                            </a>
                            for PPE Questionnaire
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* ); */}
                    {/* })} */}
                    <button
                      onClick={() => handleRegister()}
                      className="event-button rounded-xl mb-5 font-bold"
                    >
                      {/* <a href="https://www.townscript.com/e/Aquafest-Kanyakumari081023"> */}
                      Register Now!!
                      {/* </a> */}
                    </button>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="masonry-container min-h-screen bg-zinc-900 md:bg-zinc-900">
                    {Gallery.map((url) => (
                      <div key={url} className="masonry-item mt-3 mb-3">
                        <img
                          src={url}
                          alt="uploaded"
                          className="hhov mx-auto item-center justify-center"
                        />
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </Tabs>
            </div>
            <Footer />
          </div>
        )}
      </div>
    );
  }
};

export default EventDescription;
