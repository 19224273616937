import React, { useState, setState, useEffect } from "react";
import "./signin2.scss";
import "./gallery.css";
import Footer from "./footer";
import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import {
  ref,
  push,
  child,
  update,
  onValue,
  off,
  set,
  get,
} from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./nav";
import { app } from "../firebase";
import { Helmet } from "react-helmet";
import "./eventreg.css";

const database = getDatabase(app);

function Eventreg() {
  const [age, setAge] = useState(null);
  const eventName = localStorage.getItem("Event");
  const [DOB, setDOB] = useState(null);
  var menuItems = JSON.parse(localStorage.getItem(eventName));
  const [selectedOption, setSelectedOption] = useState("");
  const [discountCode, setDiscountCode] = useState("");
  const [isDiscount, setIsDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [price, setPrice] = useState(0);
  const [pay, setPay] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [actPrice, setActPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [discountSuccess, setDiscountSuccess] = useState(false);
  const [discountFailure, setDiscountFailure] = useState(false);

  const [discountLoading, setdiscountLoading] = useState(false);

  function validate() {
    if (selectedOption == "") {
      return false;
    }
    return true;
  }

  const AgeSelector = () => {
    const calculateAge = (minAge, maxAge) => {
      if (maxAge == 100 && minAge == 0) {
        return "Open";
      } else if (minAge == 0) {
        return "Below " + maxAge;
      } else if (maxAge == 100) {
        return "Above " + minAge;
      } else {
        return minAge + " - " + maxAge;
      }
    };

    const handleChange = (e) => {
      const selectedOptionValue = JSON.parse(e.target.value);
      setSelectedOption(selectedOptionValue.description);
      setActPrice(selectedOptionValue.price);
      setPrice(selectedOptionValue.price);
    };

    return (
      <div>
        <select
          style={{ color: "black" }}
          value={selectedOption}
          onChange={handleChange}
        >
          <option disabled value="">
            Select an Option
          </option>
          {menuItems.data.map((item, index) => {
            if (item && age >= item.minAge && age <= item.maxAge) {
              return (
                // {menuItems.map((item, index) => (
                <option
                  key={index}
                  className="custom-option"
                  value={JSON.stringify({
                    price: item.price,
                    description: item.description,
                  })}
                >
                  {item.description +
                    "  (" +
                    calculateAge(item.minAge, item.maxAge) +
                    ")"}
                </option>
                // ))}
              );
            } else if (item) {
              return (
                <option
                  key={index}
                  className="custom-option"
                  disabled
                  // value={item.description}
                >
                  {item.description +
                    "  (" +
                    calculateAge(item.minAge, item.maxAge) +
                    ")"}
                </option>
              );
            } // Return null for options that don't meet the condition
          })}
        </select>
      </div>
    );
  };

  useEffect(() => {
    const authUID = localStorage.getItem("authUID");
    if (authUID) {
      const userRef = ref(database, "users/" + authUID);

      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData != null) {
          setDOB(userData["DOB"]);
        }
      });
    }
  }, []);

  useEffect(() => {
    const menuRef = ref(database, "Event/" + eventName);
    onValue(menuRef, (snapshot) => {
      const data = snapshot.val();
      localStorage.setItem(eventName, JSON.stringify(data));
    });
  }, []);

  useEffect(() => {
    if (DOB) {
      console.log(DOB);
      const [year, month, day] = DOB.split("-").map(Number);
      const DOBstr = new Date(year, month - 1, day);
      const currentDate = new Date();
      const birthDate = DOBstr; // Assuming 'dob' is a Firebase Timestamp
      const ageDifference = currentDate - birthDate;
      const calculatedAge = Math.floor(
        ageDifference / (365.25 * 24 * 60 * 60 * 1000)
      );
      // console.log("Age:"+calculatedAge);
      setAge(calculatedAge);
    }
  }, [DOB]);

  useEffect(() => {
    if (localStorage.getItem("authUID") == "") {
      window.location.replace("/");
    }
  }, []);

  useEffect(() => {
    const authUID = localStorage.getItem("authUID");
    if (authUID) {
      const userRef = ref(database, "users/" + authUID);

      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData != null) {
          setName(userData["FirstName"] + " " + userData["LastName"]);
          setEmail(userData["Email"]);
        }
      });
    }
  }, []);
  const handleDiscount = () => {
    setdiscountLoading(true);
    if (discountCode) {
      const database = getDatabase(app);
      const entryRef = ref(database, "/Event/" + eventName + "/discount");
      var code = menuItems.discount;
      var myarr = code.split(",");
      let newValue = "";
      var isdis = false;
      myarr.map((item, index) => {
        var subarr = item.split(":");
        if (subarr[0] == discountCode && subarr[2] != "0") {
          setIsDiscount(true);
          setDiscount(subarr[1]);
          isdis = true;
          subarr[2] = "" + (parseInt(subarr[2]) - 1);
          setdiscountLoading(false);
          setDiscountSuccess(true);
          setDiscountFailure(false);
        }
        if (newValue == "") {
          newValue += subarr.join(":");
        } else {
          newValue += "," + subarr.join(":");
        }
      });
      if (isdis) {
        set(entryRef, newValue);
      } else {
        setDiscountFailure(true);
        setDiscountSuccess(false);
      }
    }
    setdiscountLoading(false);
  };

  const handlePay = () => {
    if(selectedOption==="")
    {
      alert("Choose a Category");
    }
    else{
    setLoading(true);

    localStorage.setItem(
      "event_det",
      JSON.stringify({
        event: eventName,
        email: email,
        name: name,
        category: selectedOption,
        authID: localStorage.getItem("authUID"),
        template_id: menuItems.temp_id,
      })
    );
    fetch("https://tri2champ-server.onrender.com/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        price: price,
        event: eventName,
        email: email,
        name: name,
        category: selectedOption,
        authID: localStorage.getItem("authUID"),
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })
      // .then(({ url}) => {
      //         window.location.href = url;

      //     })
      .then(({ session_id, url }) => {
        if (session_id != null && url != null) {
          // Store the session_id in localStorage
          localStorage.setItem("session_id", session_id);
          window.location.href = url;
          // Redirect to the success page
        }
      })
      .catch((e) => {
        console.error(e.error);

        setLoading(false);
        // You can remove the loading indicator here in case of an error
      });
    }
  };

  // const handlePay=()=>{
  //   // console.log(email+" "+name+" "+selectedOption);
  //   fetch("http://localhost:3001/create-checkout-session", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     price:price,
  //     event:eventName,
  //     email:email,
  //     name:name,
  //     category:selectedOption,
  //   }),
  // })
  //   .then(res => {
  //     if (res.ok) return res.json()
  //     return res.json().then(json => Promise.reject(json))
  //   })
  //   .then(({ url }) => {
  //     window.location = url
  //   })
  //   .catch(e => {
  //     console.error(e.error)
  //   })
  // }

  useEffect(() => {
    if (discount != 0) {
      var num = Math.ceil(actPrice * ((100 - discount) / 100));
      setPrice(num);
    }
  }, [discount]);
  return (
    <div>
      <Helmet>
        <title>Tri2Champ</title>
      </Helmet>
      <Navbar />
      <div className="bg-zinc-950 min-h-screen">
        <div className="payment-details pt-5 mx-auto bg-zinc-950">
          <div className="container10 rounded payment-form bg-zinc-950">
            <h1 className="payment-title fs-3">Payment Details</h1>

            <div className="payment-section">
              <h2 className="section-title">Category Selection</h2>
              <AgeSelector menuItems={menuItems} />
            </div>

            <div className="payment-section">
              <h2 className="section-title">Discount Code</h2>
              <input
                style={{ color: "black" }}
                placeholder="Enter Discount Code (if any)"
                className="discount-input"
                type="text"
                onChange={(e) => {
                  setDiscountCode(e.target.value);
                }}
              ></input>
              <button
                className="btn btn-primary mt-3 discount-btn"
                onClick={handleDiscount}
              >
                {discountLoading ? (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                ) : (
                  "Apply Discount"
                )}
              </button>
              {discountSuccess && (
                <div className="alert alert-success mt-3" role="alert">
                  Discount applied successfully!
                </div>
              )}
              {discountFailure && (
                <div className="alert alert-danger mt-3" role="alert">
                  Discount code is invalid or has already been used.
                </div>
              )}
            </div>

            <div className="payment-section">
              <div className="total-price  mt-3">
                <span>Total Amount:</span>
                <span>&#8377; {price}</span>
              </div>
            </div>

            <button className="btn btn-primary payment-btn" onClick={handlePay}>
              {loading ? (
                <div class="flex items-center mx-auto justify-center">
                  <p class="text-white text-center">Please wait</p>&nbsp;&nbsp;
                  <div class="loader mr-2"></div>
                </div>
              ) : (
                "Proceed to Payment"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eventreg;
