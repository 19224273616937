import React from "react";
import swim_2 from "../swim3.png";
import "./teams_section2.css";

function teams_section2() {
  return (
    <div className="bg-zinc-950 border-2 border-zinc-800">
      <div className="para mt-10 mb-10 ">
        <div className="text col-7 col-md-7 col-xl-7 col-xxl-7 col-lg-7">
          <p className="pt-5 pb-5 text-white">
            Swimming in Triathlons are tougher since it involves managing half
            hours to 1 hours in the sea. There are no lanes or markers unlike
            indoor swimming so the participant has to frequently indulge in
            ‘sighting’, raise the head to check if they are in the correct
            route. Triathlon involves taking up swimming, cycling and running
            back-to-back and swimming drains your energy before taking up the
            other tasks. I resorted to Youtube videos to learn the tips and
            tricks in the trade.
          </p>
        </div>
        <div className=" col-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 pr-3">
          <img
            src={swim_2}
            className="img-fluid border-4 border-teal-500 hover:border-x-zinc-100 hover:border-2 object-fit-cover h-1/2 w-1/2 mx-auto mb-5 mt-5"
          />
        </div>
      </div>
    </div>
  );
}

export default teams_section2;
