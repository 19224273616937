import React, { useState, useEffect } from "react";
import Navbar from "./nav";
import { initializeApp } from "firebase/app";
import Dropzone from "react-dropzone";
import {
  getStorage,
  ref as reff,
  uploadBytes,
  listAll,
  getDownloadURL,
} from "firebase/storage";
import {
  getDatabase,
  ref,
  onValue,
  push,
  update,
  remove,
} from "firebase/database";
import Footer from "./footer";
import { firebaseConfig } from "../firebase";

const firebaseApp = initializeApp(firebaseConfig, "events");
const database = getDatabase(firebaseApp);
const storage = getStorage(firebaseApp);

function newLine(text) {
  if (!text) return null;

  const sent = text;

  return sent.split("\n").map((line, index) => {
    if (line.includes("<b>") && line.includes("</b>")) {
      const parts = line.split(/(<b>.*<\/b>)/);
      return parts.map((part, i) => {
        if (part.startsWith("<b>") && part.endsWith("</b>")) {
          const boldText = part.replace(/<\/?b>/g, "");
          return <b key={index + i}>{boldText}</b>;
        } else {
          return (
            <React.Fragment key={index + i}>
              {part}
              <br />
            </React.Fragment>
          );
        }
      });
    } else {
      return (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      );
    }
  });
}

const AdminEvent = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [editItemId, setEditItemId] = useState(null);
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [field4, setField4] = useState("");
  const [field5, setField5] = useState("");
  const [field6, setField6] = useState("");
  const [field7, setField7] = useState("");
  const [field8, setField8] = useState("");
  const [field9, setField9] = useState("");
  const [field10, setField10] = useState("");
  const [field11, setField11] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [success, setSuccess] = useState("");
  const [text, setText] = useState("");
  const [styles, setStyles] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [numberInput, setNumberInput] = useState("");
  const [data, setData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [discount, setDiscount] = useState("");
  const [temp_id,setTempID]=useState("");

  useEffect(() => {
    if (
      localStorage.getItem("authUID") != "6BMmVtW9yhSU4T3CHbkRo9thNv43" &&
      localStorage.getItem("authUID") != "8kSMYknJfcZm4NKkBEp0pr0YM9M2" &&
      localStorage.getItem("authUID") != "Bq1ZwulC7XM57m5iQVZS78J9lOK2" &&
      localStorage.getItem("authUID") != "" &&
      localStorage.getItem("authUID") != ""
    ) {
      window.location.replace("/App");
    }
  }, []);

  useEffect(() => {
    const fetchMenuData = async () => {
      const menuRef = ref(database, "Event");
      onValue(menuRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const menuData = Object.entries(data).map(([key, value]) => ({
            id: key,
            ...value,
          }));
          setMenuItems(menuData);
        }
      });
    };

    fetchMenuData();
  }, []);

  const handleTextChange = (event) => {
    setText(event.target.value);
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleStyleChange = (event) => {
    const { name, value } = event.target;
    setStyles((prevStyles) => ({
      ...prevStyles,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (editItemId) {
      const menuRef = ref(database, "Event/" + itemName);
      if(numberInput==0)
      {
        update(menuRef, {
          itemName,
          description,
          date,
          price,
          field1,
          field2,
          field3,
          field4,
          field5,
          field6,
          field7,
          field8,
          field9,
          field10,
          field11,
          status,
          discount,
          temp_id,
        });
      }
      else{
        console.log("6:"+field6+"\n7:"+field7);
        update(menuRef, {
          itemName,
          description,
          price,
          field1,
          field2,
          field3,
          field4,
          field5,
          field6,
          field7,
          field8,
          field9,
          field10,
          field11,
          data,
          status,
          discount,
          temp_id,
        });
      }

      // Reset the state variables
      setItemName("");
      setDescription("");
      setPrice("");
      setField1("");
      setField2("");
      setField3("");
      setField4("");
      setField5("");
      setField6("");
      setField7("");
      setField8("");
      setField9("");
      setField10("");
      setField11("");
      setTempID("");
      setDiscount("");
      setStatus("");
      setDate("");

      setEditItemId(null);
    } else if (itemName != null) {
      const menuRef = ref(database, "Event/" + itemName);
      const eventRef = ref(database, "Event/List/");
      push(eventRef, itemName);
      const menuItem = {
        itemName,
        description,
        price,
        field1,
        field2,
        field3,
        field4,
        field5,
        field6,
        field7,
        field8,
        field9,
        field10,
        field11,
        data,
        date,
        status,
        discount,
        temp_id,
      };
      update(menuRef, menuItem);

      setItemName("");
      setDescription("");
      setPrice("");
      setField1("");
      setField2("");
      setField3("");
      setField4("");
      setField5("");
      setField6("");
      setField7("");
      setField8("");
      setField9("");
      setField10("");
      setField11("");
      setTempID("");
      setDiscount("");
      setStatus("");
      setDate("");
    }
  };

  const handleEditItem = (item) => {
    if(item.itemName)
    {
      setItemName(item.itemName);
    }
    if(item.status!==undefined)
    {
      setStatus(item.status);
    }
    if(item.description!==undefined)
    {
      setDescription(item.description);
    }
    if(item.date!==undefined)
    {
      setDate(item.date);
    }
    if(item.price!==undefined)
    {
      setPrice(item.price);
    }
    if(item.id!==undefined)
    {
      setEditItemId(item.id);
    }
    if(item.field1!==undefined)
    {
      setField1(item.field1);
    }
    if(item.field2!==undefined)
    {
      setField2(item.field2);
    }
    if(item.field3!==undefined)
    {
      setField3(item.field3);
    }
    if(item.field4!==undefined)
    {
      setField4(item.field4);
    }
    if(item.field5!==undefined)
    {
      setField5(item.field5);
    }
    if(item.field7!==undefined)
    {
      console.log(item.field7);
      setField7(item.field7);
    }
    if(item.field6!==undefined)
    {
      setField6(item.field6);
    }
    if(item.field8!==undefined)
    {
      setField8(item.field8);
    }
    if(item.field9!==undefined)
    {
      setField9(item.field9);
    }
    if(item.field10!==undefined)
    {
      setField10(item.field10);
    }
    if(item.field11!==undefined)
    {
      setField11(item.field11);
    }
    if(item.discount!==undefined)
    {
      setDiscount(item.discount);
    }
    if(item.temp_id!==undefined)
    {
      setTempID(item.temp_id);
    }
  };

  const handleDeleteItem = (itemId) => {
    const menuRef = ref(database, "Event/" + itemName);
    // const eventRef=ref(database,"Event/List/"+itemName.id+"/");
    // remove(eventRef)
    remove(menuRef);
    // .then(() => {
    //   setMenuItems((prevMenuItems) =>
    //     prevMenuItems.filter((item) => item.id !== itemId)
    //   );
    // })
    // .catch((error) => {
    //   console.log("Error deleting item:", error);
    // });
  };

  const handleFileUpload = async (files) => {
    try {
      const file = files[0];
      const storageRef = reff(storage, `/Event/${itemName}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("File uploaded successfully.");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setIsUploading(false);
      }, 3000);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false);
    }
  };

  const handleFileUpload2 = async (filess) => {
    try {
      const file = filess[0];
      const storageRef = reff(storage, `/Event/${itemName}/gallery/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      console.log("File uploaded successfully.");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setIsUploading(false);
      }, 3000);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false);
    }
  };
  const handleDate = (e) => {
    console.log(e.target.value);
    setDate(e.target.value);
  };

  const handleChange = (e) => {
    setNumberInput(e.target.value);
    
  };
  
  useEffect(() => {
    setShowDropdown(false);
    const newData = {};
    for (let i = 1; i <= numberInput; i++) {
      newData[i] = { minAge: "", maxAge: "", price: "", description: "" };
    }
    setData(newData);
    setShowDropdown(true);
  }, [numberInput]);

  // const handleGenerate = () => {
  //   setShowDropdown(false);
  //   const newData = {};
  //   for (let i = 1; i <= numberInput; i++) {
  //     newData[i] = { minAge: "", maxAge: "", price: "", description: "" };
  //   }
  //   setData(newData);
  //   setShowDropdown(true);
  // };

  const handleSave = () => {
    setShowDropdown(false);
  };

  const handleInputChange = (e, index, field) => {
    const newData = { ...data };
    newData[index][field] = e.target.value;
    setData(newData);
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          background: "linear-gradient(to left, #a492ff, #7237e7)",
        }}
      >
        <h1
          style={{
            fontSize: "200%",
            textAlign: "center",
            paddingTop: "2%",
            fontWeight: "bold",
          }}
        >
          Add Events
        </h1>
        <br />

        <form
          onSubmit={handleFormSubmit}
          style={{ textAlign: "center", justifyContent: "center" }}
          className="d-flex flex-wrap items-center"
        >
          <textarea
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900 hover:border-zinc-950"
            type="text"
            rows={10}
            placeholder="Event Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
          />
          <br />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Description for card"
            value={description}
            rows={10}
            onChange={(e) => setDescription(e.target.value)}
          />
          <br />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Price"
            value={price}
            rows={10}
            onChange={(e) => setPrice(e.target.value)}
          />
          <br />
          <textarea
            type="text"
            placeholder="Main Description(Italics)"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            value={field1}
            rows={10}
            onChange={(e) => setField1(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Mission"
            value={field2}
            onChange={(e) => setField2(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Event Highlights and Participant Facilities:"
            value={field3}
            onChange={(e) => setField3(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Mandatory Accessories"
            value={field4}
            onChange={(e) => setField4(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Event Information:"
            value={field5}
            onChange={(e) => setField5(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Race Kit Collection Requirements:"
            value={field6}
            onChange={(e) => setField6(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Consequences of Non-compliance:"
            value={field7}
            onChange={(e) => setField7(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Requesting Assistance:"
            value={field8}
            onChange={(e) => setField8(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Respect Toward Volunteers and Race Officials:"
            value={field9}
            onChange={(e) => setField9(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Identity Confirmation and Consequences:"
            value={field10}
            onChange={(e) => setField10(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Transportation Options:"
            value={field11}
            onChange={(e) => setField11(e.target.value)}
          />
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Template ID for Email"
            value={temp_id}
            onChange={(e) => setTempID(e.target.value)}
          />
          <select
            style={{ color: "black" }}
            value={status}
            onChange={handleStatus}
          >
            <option disabled value="">Choose Status</option>
            <option value="Soon">Coming Soon</option>
            <option value="upcoming">Upcoming</option>
          </select>
          <br />
          <input
            type="date"
            placeholder="Date of Event"
            onChange={handleDate}
            value={date}
            className="rounded p-3 mb-3 border border-zinc-50  w-1/2"
          ></input>
          <textarea
            type="text"
            className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
            placeholder="Discount Codes (format:any 4 digit code: discount percent in number)"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />

          <div>
            <input
              type="number"
              placeholder="Enter a number"
              value={numberInput}
              onChange={handleChange}
              className="rounded p-3 mb-3 border border-zinc-50  w-100"
            />
            <br />
            {/* <button
              onClick={handleGenerate}
              className="rounded btn-primary mb-4 border border-zinc-50 btn w-60"
            >
              Generate Dropdown
            </button> */}
            {showDropdown && (
              <div>
                {Object.keys(data).map((index) => (
                  <div key={index}>
                    <input
                      type="text"
                      placeholder="Minimum Age"
                      value={data[index].minAge}
                      className="rounded p-3 mb-3 border border-zinc-50  w-1/2"
                      onChange={(e) => handleInputChange(e, index, "minAge")}
                    />
                    <input
                      type="text"
                      placeholder="Maximum Age"
                      value={data[index].maxAge}
                      className="rounded p-3 mb-3 border border-zinc-50  w-1/2"
                      onChange={(e) => handleInputChange(e, index, "maxAge")}
                    />
                    <input
                      type="text"
                      placeholder="Price Amount"
                      className="rounded p-3 mb-3 border border-zinc-50  w-1/2"
                      value={data[index].price}
                      onChange={(e) => handleInputChange(e, index, "price")}
                    />
                    <input
                      type="text"
                      placeholder="Description"
                      className="rounded p-3 mb-3 border border-zinc-50  w-1/2"
                      value={data[index].description}
                      onChange={(e) =>
                        handleInputChange(e, index, "description")
                      }
                    />
                  </div>
                ))}
                <button className="bg-indigo-950 text-zinc-50 hover:bg-indigo-900 mt-10 rounded-lg border-2 border-zinc-50"  onClick={handleSave}>Save</button>
              </div>
            )}
          </div>

          <Dropzone onDrop={handleFileUpload} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{ marginBottom: "20px" }}
                className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
              >
                <input {...getInputProps()} />
                <p>
                  Drag and drop an image file here, or click to select a file.Drop profile image here.
                </p>
              </div>
            )}
          </Dropzone>
          <Dropzone onDrop={handleFileUpload2} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{ marginBottom: "20px" }}
                className="m-3 p-2 bg-teal-200 border-2 border-zinc-900"
              >
                <input {...getInputProps()} />
                <p>
                  Drag and drop an image file here, or click to select a file.Drop gallery images here.
                </p>
              </div>
            )}
          </Dropzone>
          {isUploading && (
            <div>
              <div className="spinner mx-auto d-block"></div>
              <h1 className="upload-text">Uploading...</h1>
            </div>
          )}
          {success && (
            <div className="fixed top-0 right-0 p-4 m-4 bg-green-500 text-white rounded-lg z-50">
              File uploaded successfully!
            </div>
          )}
          <button
            type="submit"
            className="bg-indigo-950 text-zinc-50 hover:bg-indigo-900 mt-10 rounded-lg border-2 border-zinc-50"
          >
            {editItemId ? "Update Event" : "Add Event"}
          </button>
        </form>

        <div
          className="mt-20"
          style={{
            background: "rebeccapurple",
            paddingLeft: "12%",
            paddingRight: "12%",
            justifyContent: "center",
            textAlign: "justify",
          }}
        >
          <div className="menu-items pb-20 text-zinc-50">
            <h1
              style={{
                color: "#0ccfbc",
                fontSize: "6vh",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              User's View
            </h1>
            {menuItems.map((item, index) => (
              <div key={index} className="menu-items">
                <h1
                  className=" menu-title  pb-8 pt-2"
                  style={{ textAlign: "center", fontSize: "6vh" }}
                >
                  <br />
                  {newLine(item.itemName)}
                </h1>
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt="Event"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                <div className="fs-2 mb-5">
                  <span className="main-titles font-semibold fs-2">
                    Event Description
                  </span>
                  <br />
                  {newLine(item.description)}
                </div>

                <div className="fs-2 mb-5">
                  <span className="main-titles font-semibold fs-2">Fees</span>
                  <br />
                  {newLine(item.price)}
                </div>

                <p className="event-field">
                  <br />
                  {newLine(item.field1)}
                </p>

                <p className="event-field">
                  <br />
                  {newLine(item.field2)}
                </p>
                {item.imageUrl && (
                  <img
                    src={item.imageUrl}
                    alt="Event2"
                    style={{ maxWidth: "100%" }}
                  />
                )}
                <p className="event-field">
                  <br />
                  {newLine(item.field3)}
                </p>
                <p className="event-field">
                  <br />
                  {newLine(item.field4)}
                </p>
                <p className="event-field">
                  <br />
                  {newLine(item.field5)}
                </p>
                <button
                  className="bg-indigo-950 text-zinc-50 rounded-lg hover:bg-indigo-900 mt-10 border border-zinc-50"
                  onClick={() => handleEditItem(item)}
                >
                  Edit Event
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminEvent;
