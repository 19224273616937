import React, { useState, useEffect } from "react";

function Final() {
  useEffect(()=>{
    localStorage.setItem("event_det", "");
    localStorage.setItem("session_id", "");
  },[]);
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-950">
      <div className="bg-gray-200 p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-primary mb-4">
          Thank you for registering for the event.
          <br /> Your registration is successful
        </h1>
        <p className="text-gray-600 mb-4 font-semibold">
          Check your email for more information.
        </p>

        <button
          className="mt-4 rounded-full w-1/2 bg-black font-bold text-orange-400 text-2xl border border-input hover:bg-accent hover:text-accent-foreground"
          variant="ghost"
          onClick={() => (window.location.href = "/UserPage")}
        >
          Return to home
        </button>
      </div>
    </div>
  );
}

export default Final;
