import React, { useState, useEffect } from "react";
import "./signin.scss";
import logo from "../logo1.png";
import Footer from "./footer";
import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";
import { ref, push, child, update, onValue } from "firebase/database";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./nav";
import styles from "./signin.module.scss";
import Tilt from "react-parallax-tilt";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { firebaseConfig } from "../firebase";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

function SetProfile() {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [dob, setDOB] = useState(null);
  const [phone, setPhone] = useState(null);
  const [city, setCity] = useState(null);
  const [weight, setWeight] = useState(null);
  const [state, setState] = useState(null);
  const [isEditMode, setIsEditMode] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploading2, setIsUploading2] = useState(false);
  const [profileImage2, setProfileImage2] = useState(null);
  const [isUploading3, setIsUploading3] = useState(false);
  const [profileImage3, setProfileImage3] = useState(null);
  const [isUploading4, setIsUploading4] = useState(false);
  const [profileImage4, setProfileImage4] = useState(null);
  const [preEdit, setPreEdit] = useState(false);


  function validate() {
    if (!firstName || !/^[A-Za-z -]{3,25}$/.test(firstName.trim())) {
      alert("Invalid First Name");
      return false;
    }
    if (!lastName || !/^[A-Za-z -]{3,25}$/.test(lastName.trim())) {
      alert("Invalid Last Name");
      return false;
    }
    if(!dob)
    {
      alert("DOB not Entered");
      return false;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Invalid email address");
      return false;
    }

    if (!/^\d{10}$/.test(phone)) {
      alert("Invalid phone number");
      return false;
    }

    if (!weight || !/^\d{2,3}$/.test(weight)) {
      alert("Invalid Weight");
      return false;
    }

    if (!state || !/^[A-Za-z -]{3,25}$/.test(state.trim())) {
      alert("Invalid state");
      return false;
    }

    if (!city || !/^[A-Za-z -]{3,25}$/.test(city.trim())) {
      alert("Invalid city");
      return false;
    }

    return true;
  }

  useEffect(() => {
    const authUID = localStorage.getItem("authUID");
    if (authUID) {
      const cachedProfileData = localStorage.getItem(`profileData_${authUID}`);
      if (cachedProfileData) {
        const parsedData = JSON.parse(cachedProfileData);
        setFirstName(parsedData["FirstName"]);
        setLastName(parsedData["LastName"]);
        setDOB(parsedData["DOB"]);
        setEmail(parsedData["Email"]);
        setPhone(parsedData["Phone"]);
        setCity(parsedData["City"]);
        setState(parsedData["State"]);
        setWeight(parsedData["Weight"]);
        if(parsedData["FirstName"]!=="")
        {
          setPreEdit(true);
        }
      } else {
        const userRef = ref(database, "users/" + authUID);
        onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData != null) {
            setFirstName(userData["FirstName"]);
            setLastName(userData["LastName"]);
            setDOB(userData["DOB"]);
            setEmail(userData["Email"]);
            setPhone(userData["Phone"]);
            setCity(userData["City"]);
            setState(userData["State"]);
            setWeight(userData["Weight"]);
            retrieveImageURL();

            const cachedData = JSON.stringify(userData);
            localStorage.setItem(`profileData_${authUID}`, cachedData);
            if(userData["FirstName"]!=="")
            {
              setPreEdit(true);
            }
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    const authUID = localStorage.getItem("authUID");
    if (authUID) {
      const userRef = ref(database, "users/" + authUID);

      onValue(userRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData != null) {
          setFirstName(userData["FirstName"]);
          setLastName(userData["LastName"]);
          setDOB(userData["DOB"]);
          setEmail(userData["Email"]);
          setPhone(userData["Phone"]);
          setCity(userData["City"]);
          setState(userData["State"]);
          setWeight(userData["Weight"]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("authUID") === "") {
      window.location.replace("/App");
    } 
  }, []);

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "firstName") {
      setFirstName(value);
    }
    if (id === "lastName") {
      setLastName(value);
    }
    if (id === "email") {
      setEmail(value);
    }
    if (id === "dob") {
      setDOB(value);
    }
    if (id === "phone") {
      setPhone(value);
    }
    if (id === "weight") {
      setWeight(value);
    }
    if (id === "city") {
      setCity(value);
    }
    if (id === "state") {
      setState(value);
    }
  };

  useEffect(() => {
    if (firstName !== null&&firstName!=="") {
      retrieveImageURL();
      retrieveImageURL2();
      retrieveImageURL3();
      retrieveImageURL4();
    }
  }, [firstName]);

  const handleSubmit = () => {
    if (validate()) {
      let obj = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        Phone: phone,
        DOB: dob,
        Weight: weight,
        City: city,
        State: state,
      };

      const newUserId = localStorage.getItem("authUID");

      const updates = {};
      updates["/users/" + newUserId] = obj;
      update(ref(database), updates);
      setFormSubmitted(true);
      setTimeout(() => {
        setFormSubmitted(false);
      }, 2500);
      localStorage.setItem("isEdited", "true");
      window.location.replace("./register");
    }
  };

  const handleImageUpload = async (file) => {
    setIsUploading(true);

    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/profile_pic/${firstName}1`;
    const imageRef = storageRef(storage, storagePath);

    try {
      await uploadBytes(imageRef, file);
      setImageUploaded(true);
      retrieveImageURL();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading(false);
    }
  };
  const handleImageUpload2 = async (file) => {
    setIsUploading2(true);

    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/photo_ID/${firstName}2`;
    const imageRef = storageRef(storage, storagePath);

    try {
      await uploadBytes(imageRef, file);
      setImageUploaded(true);
      retrieveImageURL2();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading2(false);
    }
  };

  const handleImageUpload3 = async (file) => {
    setIsUploading3(true);

    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/medical_certificate/${firstName}3`;
    const imageRef = storageRef(storage, storagePath);

    try {
      await uploadBytes(imageRef, file);
      setImageUploaded(true);
      retrieveImageURL3();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading3(false);
    }
  };

  const handleImageUpload4 = async (file) => {
    setIsUploading4(true);

    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/insurance/${firstName}4`;
    const imageRef = storageRef(storage, storagePath);

    try {
      await uploadBytes(imageRef, file);
      setImageUploaded(true);
      retrieveImageURL4();
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsUploading4(false);
    }
  };

  const retrieveImageURL = async () => {
    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/profile_pic/${firstName}1`;
    const imageRef = storageRef(storage, storagePath);

    try {
      const downloadURL = await getDownloadURL(imageRef);
      setProfileImage(downloadURL);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };
  const retrieveImageURL2 = async () => {
    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/photo_ID/${firstName}2`;
    const imageRef = storageRef(storage, storagePath);

    try {
      const downloadURL = await getDownloadURL(imageRef);
      setProfileImage2(downloadURL);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };

  const retrieveImageURL3 = async () => {
    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/medical_certificate/${firstName}3`;
    const imageRef = storageRef(storage, storagePath);

    try {
      const downloadURL = await getDownloadURL(imageRef);
      setProfileImage3(downloadURL);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };

  const retrieveImageURL4 = async () => {
    const storagePath = `profile_images/${localStorage.getItem(
      "authUID"
    )}/insurance/${firstName}4`;
    const imageRef = storageRef(storage, storagePath);

    try {
      const downloadURL = await getDownloadURL(imageRef);
      setProfileImage4(downloadURL);
    } catch (error) {
      console.error("Error retrieving image URL:", error);
    }
  };

  return (
    <>
      <Navbar />
      <div style={{ background: "linear-gradient(to left, #a492ff, #7237e7)" }}>
        <div>
          <div className="user-registration ">
            <div className="container register">
              <div className="">
                <div className="col-md-10  register-right mx-auto d-block">
                  <div className="tab-content" id="myTabContent">
                    <div className="register-heading  pb-3">
                      <h3 className="pb-4 user-profile ">User Profile</h3>
                      <div className="d-flex flex-col justify-content-center  align-items-center">
                        <div className="profile-image-container ">
                          {isUploading ? (
                            <div className="loading-spinner d-flex justify-content-center align-items-center"></div>
                          ) : profileImage ? (
                            <Tilt>
                              <img
                                src={profileImage}
                                alt="Profile"
                                className="profile-image "
                              />
                            </Tilt>
                          ) : (
                            <div className="no-profile-image">
                              No Profile Image
                            </div>
                          )}
                        </div>
                        <br />

                        {isEditMode && !isUploading && (
                          <div className="mx-auto items-center  text-red-400">
                            <label className="upload-button  rounded pt-3 text-red-400">
                              Upload / Change Image
                            </label>
                            <br />
                            <label className="upload-button text-xs  rounded pt-3 text-orange-400">
                              (your recent photograph){" "}
                            </label>
                            &nbsp;&nbsp;
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) =>
                                handleImageUpload(e.target.files[0])
                              }
                            />
                          </div>
                        )}
                      </div>

                      <div className="register-heading ">
                        <div className="d-flex flex-col justify-content-center align-items-center">
                          <div className="profile-image-container ">
                            {isUploading2 ? (
                              <div className="loading-spinner d-flex justify-content-center align-items-center"></div>
                            ) : profileImage2 ? (
                              <Tilt>
                                <img
                                  src={profileImage2}
                                  alt="Profile"
                                  className="profile-image "
                                />
                              </Tilt>
                            ) : (
                              <div className="no-profile-image">
                                No Photo ID
                              </div>
                            )}
                          </div>
                          {isEditMode && !isUploading2 && (
                            <label className="upload-button  rounded pt-3 text-red-400">
                              Upload / Change Photo ID
                              <br />
                              <a
                                data-tooltip-id="my-tooltip"
                                data-tooltip-content="You can also provide your photo id in person but it 
                              is mandatory to submit these details else it would lead to disqualification."
                              >
                                ⓘ
                              </a>
                              <Tooltip
                                effect="solid"
                                id="my-tooltip"
                                style={{
                                  width: "200px",
                                  padding: "10px",
                                  fontSize: "11px",
                                }}
                              />
                              <br />
                              <label className="upload-button text-xs  rounded pt-3 text-orange-400">
                                (aadhar card, voter id, pan card, driving
                                licence){" "}
                              </label>
                              &nbsp;&nbsp;
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  handleImageUpload2(e.target.files[0])
                                }
                              />
                            </label>
                          )}
                        </div>
                        <div className="register-heading  ">
                          <div className="d-flex flex-col justify-content-center align-items-center">
                            <div className="profile-image-container ">
                              {isUploading3 ? (
                                <div className="loading-spinner d-flex justify-content-center align-items-center"></div>
                              ) : profileImage3 ? (
                                <Tilt>
                                  <img
                                    src={profileImage3}
                                    alt="Medical"
                                    className="profile-image "
                                  />
                                </Tilt>
                              ) : (
                                <div className="no-profile-image">
                                  No Medical Certificate
                                </div>
                              )}
                            </div>
                            {isEditMode && !isUploading3 && (
                              <label className="upload-button  rounded pt-3 text-red-400">
                                Upload / Change Medical Certificate
                                <a
                                  data-tooltip-id="my-tooltip"
                                  data-tooltip-content="You can also provide your medical certificate in person but it 
                              is mandatory to submit these details else it would lead to disqualification."
                                >
                                  ⓘ
                                </a>
                                <Tooltip
                                  effect="solid"
                                  id="my-tooltip"
                                  style={{
                                    width: "200px",
                                    padding: "10px",
                                    fontSize: "11px",
                                  }}
                                />
                                <br />
                                <label className="upload-button text-xs  rounded pt-3 text-orange-400">
                                  (upload the most recent one){" "}
                                </label>
                                &nbsp;&nbsp;
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleImageUpload3(e.target.files[0])
                                  }
                                />
                              </label>
                            )}
                          </div>

                          <div className="register-heading pb-5">
                            <div className="d-flex flex-col justify-content-center align-items-center">
                              <div className="profile-image-container ">
                                {isUploading4 ? (
                                  <div className="loading-spinner d-flex justify-content-center align-items-center"></div>
                                ) : profileImage4 ? (
                                  <Tilt>
                                    <img
                                      src={profileImage4}
                                      alt="Profile"
                                      className="profile-image "
                                    />
                                  </Tilt>
                                ) : (
                                  <div className="no-profile-image">
                                    No Insurance{" "}
                                  </div>
                                )}
                                <br />
                              </div>

                              {isEditMode && !isUploading4 && (
                                <label className="upload-button  rounded pt-3 text-red-400">
                                  Upload / Change Insurance
                                  <a
                                    data-tooltip-id="my-tooltip"
                                    data-tooltip-content="It is optional but it could be helpful to cover the travel or medical insurance."
                                  >
                                    ⓘ
                                  </a>
                                  <Tooltip
                                    effect="solid"
                                    id="my-tooltip"
                                    style={{
                                      width: "100px",
                                      padding: "10px",
                                      fontSize: "11px",
                                    }}
                                  />
                                  <br />
                                  <label className="upload-button text-xs  rounded pt-3 text-orange-400">
                                    (medical or travel insurance){" "}
                                  </label>
                                  &nbsp;&nbsp;
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      handleImageUpload4(e.target.files[0])
                                    }
                                  />
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade show active"
                        id="home"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                      >
                        <div className="row register-form">
                          <div className="col-md-6">
                            <div className="form-group">
                              {isEditMode && !preEdit ? (
                                <input
                                  type="text"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="First Name"
                                  value={firstName}
                                  id="firstName"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  First Name:{" "}
                                  <span className="text-gradientt">
                                    {firstName}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode && !preEdit ? (
                                <input
                                  type="text"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="Last Name"
                                  value={lastName}
                                  id="lastName"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  Last Name:{" "}
                                  <span className="text-gradientt">
                                    {lastName}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode && !preEdit ? (
                                <input
                                  type="date"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="DOB"
                                  value={dob}
                                  id="dob"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  DOB:{" "}
                                  <span className="text-gradientt">{dob}</span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode ? (
                                <input
                                  type="text"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="State"
                                  value={state}
                                  id="state"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  State:{" "}
                                  <span className="text-gradientt">
                                    {state}
                                  </span>
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              {isEditMode ? (
                                <input
                                  type="email"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="Email"
                                  value={email}
                                  id="email"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  Email:{" "}
                                  <span className="text-gradientt">
                                    {email}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode ? (
                                <input
                                  type="text"
                                  minLength="10"
                                  maxLength="10"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="Phone"
                                  id="phone"
                                  onChange={handleChange}
                                  value={phone}
                                />
                              ) : (
                                <p className="text-gradient">
                                  Phone:{" "}
                                  <span className="text-gradientt">
                                    {phone}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode ? (
                                <input
                                  type="text"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="Weight"
                                  value={weight}
                                  id="weight"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  Weight:
                                  <span className="text-gradientt">
                                    {" "}
                                    {weight}
                                  </span>
                                </p>
                              )}
                            </div>
                            <div className="form-group">
                              {isEditMode ? (
                                <input
                                  type="text"
                                  className="form-control rounded-lg mt-3"
                                  placeholder="City"
                                  value={city}
                                  id="city"
                                  onChange={handleChange}
                                />
                              ) : (
                                <p className="text-gradient">
                                  City:{" "}
                                  <span className="text-gradientt">{city}</span>
                                </p>
                              )}
                            </div>

                            {isEditMode ? (
                              <button
                                className="btnRegister bg-emerald-500 mb-52"
                                onClick={handleSubmit}
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                className="btnRegister bg-emerald-500"
                                onClick={toggleEditMode}
                              >
                                Edit
                              </button>
                            )}
                            {formSubmitted && (
                              <div
                                style={{ fontSize: "2.5vh" }}
                                className="success-message rounded-pill"
                              >
                                Profile Updated Successfully!
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetProfile;
