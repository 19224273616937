import React from "react";
import cycle1 from "../coach.jpg";
import "./sec0.css";

function teams_section3() {
  return (
    <div
      className="bg-zinc-950 border-2 border-zinc-800"
      style={{}}
      id="teams_sec1"
    >
      <div className="para xl:pr-20 sm:pr-0 md:pr-0 lg:pr-20">
        <div className="cycl col-5 items-center justify-center mx-auto col-md-5 col-lg-5 col-xl-5 col-xxl-5">
          <img
            src={cycle1}
            className="img-fluid border-4 border-teal-500 hover:border-x-zinc-100 hover:border-2 h-1/2 w-1/2 mx-auto mt-5"
          />{" "}
          {/* Add mx-auto */}
          <p className="text mt-3 mb-5 text-center text-white">
            Shivaraju R M (known as Shivaraju Mallan)
          </p>
        </div>

        <div className="text col-7 col-md-7 col-xl-7 col-xxl-7 col-lg-7 pr-10">
          <div className="glitter pt-3 pb-2 bg-gradient-to-r text-cyan-500 text-blue-500">
            <b style={{ fontSize: "130%" }}> </b>
          </div>

          <p className="pb-3 pr-4 text-white">
            Professional Basketball player Triathlete, Trail Runner, Cyclist,
            open water swimmer, Trekker Coach & Fitness trainer - Basketball,
            Football, Kho kho, Kabaddi, Volleyball. Silambam - State-level
            champion, Coach( Martial art) 30 years of experience. Explorer in
            motorbike and cycle.
          </p>
        </div>
      </div>
    </div>
  );
}

export default teams_section3;
