import { getDatabase, update, ref, set, get } from "firebase/database";
import React, { useEffect, useState } from "react";
import { app } from "../firebase";
import toast from "react-hot-toast";
import emailjs from "emailjs-com";

const Success = () => {
  // Extract query parameters from the URL
  const session_id = localStorage.getItem("session_id");
  const [code, setCode] = useState("");
  const [even, setEvent] = useState("");
  const handleAfterPay = async () => {
    const obj = JSON.parse(localStorage.getItem("event_det"));
    await sendEmail(
      obj.email,
      obj.name,
      obj.category,
      obj.event,
      obj.template_id
    );
    await dbEntry(obj);
    window.location.replace("/final");
  };
  const handlereturn = () => {
    localStorage.setItem("event_det", "");
    localStorage.setItem("session_id", "");
    // localStorage.setItem("discountCode","");
  };
  const dbEntry = async (obj) => {
    const database = getDatabase(app);
    const updates = {};
    updates["/Event/" + obj.event + "/Participants/" + obj.authID] = obj;
    await update(ref(database), updates);
  };

  const sendEmail = async (email, name, category, event, template_id) => {
    try {
      console.log(email + " " + name + " " + category + " " + event);
      const emailData = {
        to_email: email, // Replace with the recipient's email address
        to_name: name,
        category: category,
        event: event,
      };
      const result = emailjs.send(
        "service_ol721aw",
        template_id,
        emailData,
        "odYM0GOidkMK3vBfk"
      );
      // console.log('Email sent successfully:', result);
      return "success";
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  };

  useEffect(() => {
    if (session_id) {
      fetch("https://tri2champ-server.onrender.com/confirm-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ session_id }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            handleAfterPay();
          } else {
            toast.error("Payment confirmation failed:", data.error);
            window.location.replace("/");
          }
        })
        .catch((error) => {
          toast.error("Error confirming payment:", error);
          window.location.replace("/");
        });
    } else {
      window.location.replace("/");
    }
  }, [session_id]);

  return (
    <div class="bg-gray-900 min-h-screen flex items-center justify-center">
      <div class="bg-gray-900 min-h-screen flex items-center justify-center">
        <h1 class="text-white font-bold">
          <div class="flex items-center">
            <div class="loader mr-2"></div>&nbsp;&nbsp;
            <p class="text-white text-center">Confirming Payment</p>
          </div>
        </h1>
      </div>
    </div>
  );
};

export default Success;
